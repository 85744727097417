/* Buttons */

/* Tabs */
.amplify-tab-item[data-state='active']:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border-bottom: 4px solid #ae3737;
}

.amplify-tabs-item[data-state='active'] {
	border-color: #ae3737;
}

.amplify-label {
	font-weight: 700;
}

.amplify-button--link {
	color: #ae3737;
}

.amplify-authenticator [data-variation='link']:hover,
.amplify-authenticator [data-variation='link']:focus {
	color: #bf6363 !important;
}
